import React from 'react'
import * as Icon from 'react-feather'

const navigationConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'Google Business Profile',
  },
  {
    id: 'locations',
    title: 'Locations',
    type: 'collapse',
    icon: <Icon.MapPin size={20} />,
    children: [
      {
        id: 'myLocations',
        title: 'My Locations',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/',
      },
      {
        id: 'pendingEdits',
        title: 'Pending Edits',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/pending',
      },
      {
        id: 'performance',
        title: 'Performance',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/score',
      },
    ],
  },
  {
    id: 'posts',
    title: 'Posts',
    type: 'collapse',
    icon: <Icon.Edit size={20} />,
    permission: ['posts'],
    children: [
      {
        id: 'new_offer',
        title: 'Add Offer',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/posts/offer',
      },
      {
        id: 'new_cta',
        title: "What's New",
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/posts/cta',
      },
      {
        id: 'new_event',
        title: 'Event',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/posts/event',
      },
      {
        id: 'new_alert',
        title: 'COVID-19',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/posts/alert',
      },
    ],
  },
  {
    id: 'bulkEdit',
    title: 'Informations',
    type: 'collapse',
    icon: <Icon.Map size={20} />,
    permission: ['infos'],
    children: [
      {
        id: 'bulkEditGeneral',
        title: 'General',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/edit/general',
      },
      {
        id: 'bulkEditImages',
        title: 'Images',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/edit/images',
      },
      {
        id: 'questionsAndAnswer',
        title: 'FAQ',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/answered-questions',
      },
      {
        id: 'socialMedia',
        title: 'Social Media',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/edit/social-media',
      },
      {
        id: 'bulkEditUpdateCSV',
        title: 'Import CSV',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/locations/edit/import',
      },
    ],
  },
  {
    id: 'insights',
    title: 'Insights',
    type: 'collapse',
    icon: <Icon.BarChart2 size={20} />,
    permission: ['insights'],
    subscribed: true,
    children: [
      {
        id: 'insights',
        title: 'General',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/insights',
        permission: ['insights'],
      },
      {
        id: 'insightsKeywords',
        title: 'Keywords',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/insights/keywords',
        permission: ['keywords'],
      },
      {
        id: 'insightsLocations',
        title: 'Locations',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: '/insights/locations',
        permission: ['insights'],
      },
    ],
  },
  {
    id: 'questions',
    title: 'Questions',
    type: 'item',
    icon: <Icon.HelpCircle size={16} />,
    navLink: '/locations/questions',
    permission: ['questions'],
  },
  {
    id: 'reviews',
    title: 'Reviews',
    type: 'item',
    icon: <Icon.Star size={16} />,
    navLink: '/reviews',
    permission: ['reviews'],
  },
  {
    id: 'notifications',
    title: 'Notifications',
    type: 'item',
    icon: <Icon.Bell size={16} />,
    navLink: '/notifications',
    permission: ['notifications'],
  },
  {
    id: 'schedules',
    title: 'Schedules',
    type: 'item',
    icon: <Icon.Calendar size={16} />,
    navLink: '/publications',
    permission: ['schedules'],
  },
  {
    id: 'nps',
    title: 'NPS',
    type: 'item',
    icon: <Icon.Smile size={16} />,
    navLink: '/nps',
    permission: ['schedules'],
  },
  {
    id: 'help',
    title: 'Help Center',
    type: 'item',
    icon: <Icon.HelpCircle size={16} />,
    navLink: '/help-center',
  },
]

export default navigationConfig
