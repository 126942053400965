import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { HelpCircle, Home, Star } from 'react-feather'

import { FormattedMessage } from 'react-intl'
import { queries } from '~/constants/mediaQuery'

const Footer = props => {
  const path = window.location.pathname
  const footerTypeArr = ['sticky', 'static', 'hidden']
  return (
    <>
      <BottomNav>
        <nav>
          <ul>
            <li>
              <a
                href="/"
                data-active={path === '/'}
                className="d-flex flex-column align-items-center"
              >
                <Home size={24} />
                <p className="m-0">
                  <FormattedMessage id="Home" />
                </p>
              </a>
            </li>
            <li>
              <a
                href="/reviews"
                data-active={path.startsWith('/reviews')}
                className="d-flex flex-column align-items-center"
              >
                <Star size={24} />
                <p className="m-0">
                  <FormattedMessage id="Reviews" />
                </p>
              </a>
            </li>
            <li>
              <a
                href="/questions"
                data-active={path.startsWith('/questions')}
                className="d-flex flex-column align-items-center"
              >
                <HelpCircle size={24} />
                <p className="m-0">
                  <FormattedMessage id="Questions" />
                </p>
              </a>
            </li>
          </ul>
        </nav>
      </BottomNav>

      <footer
        className={
          'd-flex ' +
          classnames('footer footer-light', {
            'footer-static':
              props.footerType === 'static' ||
              !footerTypeArr.includes(props.footerType),
            'd-none': props.footerType === 'hidden',
          })
        }
      >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            Copyright © {new Date().getFullYear()}{' '}
            <FormattedMessage id="Locasty, All rights reserved" />
          </span>
        </p>
      </footer>
    </>
  )
}

const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #00000026;

  @media ${queries.more.sm} {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      width: 100%;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1.5rem 1.5rem;
        color: #2f2b3de5;

        &[data-active='true'] {
          color: #fff;
          background: linear-gradient(
              270deg,
              rgba(115, 103, 240, 0.7) 0%,
              #7367f0 100%
            ),
            #fafafa;
        }

        p {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          margin-top: 0.5rem !important;
        }
      }
    }
  }
`

export default Footer
