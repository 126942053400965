import React, { useEffect } from 'react'
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap'
import axios from 'axios'
import * as Icon from 'react-feather'
import ReactCountryFlag from 'react-country-flag'
import { useSetState } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'

import { IntlContext } from '~/utility/context/Internationalization'
import Avatar from '~/components/Avatar'
import { FormattedMessage } from 'react-intl'
import { Show } from '~/components/commons'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { toggleInviteModal } from '~/store/modules/navbar/actions'
import { ADMIN, CLIENT_ADMIN, SUPER_ADMIN } from '~/constants/roles'

function UserDropdownItem({ href, label, children }) {
  const history = useHistory()
  return (
    <DropdownItem className="w-100" onClick={() => history.push(href)}>
      {React.cloneElement(children, { size: 14, className: 'mr-50' })}
      <span className="align-middle">
        <FormattedMessage id={label} />
      </span>
    </DropdownItem>
  )
}

function UserDropdown(props) {
  const userProfile = useSelector(state => state.user?.profile)
  const userRole = userProfile?.role

  return (
    <DropdownMenu right>
      <UserDropdownItem href="/profile" label="Profile">
        <Icon.User />
      </UserDropdownItem>

      <Show condition={userRole >= ADMIN}>
        <UserDropdownItem href="/accounts" label="Accounts">
          <Icon.Menu />
        </UserDropdownItem>
      </Show>

      <UserDropdownItem href="/projects" label="Projects">
        <Icon.Grid />
      </UserDropdownItem>

      <Show condition={userRole >= CLIENT_ADMIN}>
        <UserDropdownItem href="/users" label="Users">
          <Icon.Users />
        </UserDropdownItem>
      </Show>

      <Show condition={userRole >= CLIENT_ADMIN}>
        <UserDropdownItem href="/account/settings" label="Settings">
          <Icon.Settings />
        </UserDropdownItem>
      </Show>

      <Show condition={userRole >= SUPER_ADMIN}>
        <UserDropdownItem href="/tutorials" label="Tutorials">
          <Icon.HelpCircle />
        </UserDropdownItem>
      </Show>

      <Divider />
      <LogoutDropdown
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
          return props.logoutWithJWT()
        }}
        style
      >
        <Icon.Power size={14} className="mr-50 text-danger" />
        <span className="align-middle text-danger">
          <FormattedMessage id="Log Out" />
        </span>
      </LogoutDropdown>
    </DropdownMenu>
  )
}

function NavbarUser(props) {
  const [state, setState] = useSetState({
    navbarSearch: false,
    langDropdown: false,
  })
  const userProfile = useSelector(state => state.user?.profile)
  const userRole = userProfile?.role

  const canImportProject = useSelector(
    state => state.user?.profile?.account?.importProject
  )

  useEffect(() => {
    const initialize = async () => {
      const { data } = await axios.get('/api/main-search/data')

      setState({ suggestions: data.searchResult })
    }

    initialize()
  }, [setState])

  const handleLangDropdown = () =>
    setState({ langDropdown: !state.langDropdown })

  const dispatch = useDispatch()

  return (
    <ul className="nav navbar-nav navbar-nav-user w-100 d-flex justify-content-end">
      <Show condition={canImportProject && userRole >= CLIENT_ADMIN}>
        <li className="nav-item ml-auto d-flex align-items-center">
          <Button
            color="link p-50"
            onClick={() => dispatch(toggleInviteModal())}
          >
            <Icon.PlusSquare className="text-primary" size={18} />
          </Button>
        </li>
      </Show>

      <IntlContext.Consumer>
        {context => {
          const langArr = {
            en: 'English',
            br: 'Português',
          }
          return (
            <Dropdown
              tag="li"
              className="dropdown-language nav-item ml-1"
              isOpen={state.langDropdown}
              toggle={handleLangDropdown}
              data-tour="language"
            >
              <DropdownToggle tag="a" className="nav-link">
                <ReactCountryFlag
                  className="country-flag"
                  countryCode={
                    context.state.locale === 'en' ? 'us' : context.state.locale
                  }
                  svg
                />
                <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                  {langArr[context.state.locale]}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  tag="a"
                  onClick={() => context.switchLanguage('en')}
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode="us"
                    svg
                  />
                  <span className="ml-1">English</span>
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={() => context.switchLanguage('br')}
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode="br"
                    svg
                  />
                  <span className="ml-1">Português</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        }}
      </IntlContext.Consumer>

      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">{userProfile.name}</span>
            <span className="user-status">{userProfile?.account?.name}</span>
          </div>
          <span data-tour="user">
            <Avatar
              className="m-0"
              bgColor="#EBE9FD"
              image={props.userImg}
              icon={!props.userImg}
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )
}

const Divider = styled.div`
  height: 1px;
  width: calc(100% + 10px);
  margin: 4px 0 4px -5px;
  background-color: #eeee;
`
const LogoutDropdown = styled(DropdownItem)`
  &:hover {
    svg {
      color: #fff !important;
    }
    span {
      color: #fff !important;
    }
  }
`

export default NavbarUser
